import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import 'animate.css';

import Sliders from './Sliders';
import Style from './Style';
import './style.scss';

// Sliders  
document.addEventListener('DOMContentLoaded', () => {
	const allSliders = document.querySelectorAll('.wp-block-bsb-slider');
	allSliders.forEach(slidersEl => {
		if (!slidersEl.dataset.attributes) {
			return;
		}
		const attributes = JSON.parse(slidersEl.dataset.attributes);
		const { cId, sliders, title, titleFCaption, position, titleAnimation, descAnimation, btnAnimation, isLazyLoad } = attributes;

		const { tag = "h5" } = title;
		createRoot(slidersEl).render(<>
			<Style attributes={attributes} clientId={cId} />

			<Sliders attributes={attributes} clientId={cId} custom={{}}>
				<div className="carousel-inner">
					{sliders.map((slider, index) => {
						const { img, title, desc, btnLabel, btnUrl, target } = slider || {};
						const titleCheck = titleFCaption ? img?.caption || img?.title : title != null ? title : img?.caption || img?.title;
						const captionClass = position?.top ? ('0' === position.top ? `center-center` : 'bottom-center') : position?.split(' ')?.join('-');

						return <div key={index} className={`carousel-item animate__animated ${index === 0 ? 'active' : ''}`}>
							{img?.url && <> {isLazyLoad ? <img loading="lazy" data-src={img.url} className="d-block w-100 lazyload" alt={img?.alt || img?.title} /> : <img src={img.url} className="d-block w-100 " alt={img?.alt || img?.title} />}</>}

							<div className="carousel-caption">
								<div className={`captionContent ${captionClass}`}>
									{(title || img?.caption || img?.title) && createElement(tag, {
										className: `bsbTitle animate__animated animate__${titleAnimation?.effect}`, dangerouslySetInnerHTML: { __html: titleCheck }
									}, null)}

									{desc && <>
										<p className={`animate__animated animate__${descAnimation?.effect}`} dangerouslySetInnerHTML={{ __html: desc }} />
									</>}
									{btnLabel && <>
										<div className={`carousel-button animate__animated animate__${btnAnimation?.effect}`}>
											<a href={btnUrl} target={target ? '_blank' : ''} rel="noreferrer" dangerouslySetInnerHTML={{ __html: btnLabel }} />
										</div>
									</>}
								</div>
							</div>
						</div>
					})}
				</div>
			</Sliders>
		</>);

		slidersEl?.removeAttribute('data-attributes');
	});
});